import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setAnalyticsOrigin } from 'dpl/shared/utils/setAnalyticsOrigin';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import {
  BREEDER_POST_ID,
  SLIDE_IDX,
  BREEDER_PROFILE_ID
} from 'dpl/constants/query_params';
import { pushParams, resetParams } from 'dpl/actions/query_params';

import { breederPostQueryDefinition } from '../utils/resourceDefinitions';

export default function useBreederPostData() {
  const dispatch = useDispatch();

  const { breederProfileId, breederPostId, selectedMediaIdx } = useSelector(
    ({ queryParams, server }) => ({
      breederPostId: Number(queryParams[BREEDER_POST_ID]),
      breederProfileId:
        Number(queryParams[BREEDER_PROFILE_ID]) ||
        Number(server.breederProfileId),
      selectedMediaIdx: Number(queryParams[SLIDE_IDX]) || 0 // media grid
    })
  );

  const isBreederPostModalOpen = Boolean(breederPostId);

  const breederPostData = useResourceQuery({
    ...breederPostQueryDefinition(breederProfileId, breederPostId),
    enabled: Boolean(breederProfileId && breederPostId)
  });

  useEffect(() => {
    if (isBreederPostModalOpen) {
      breederPostData.refetch();
    }
  }, [isBreederPostModalOpen]);

  function resetBreederPostParams() {
    setAnalyticsOrigin({
      originViewFeatureLocation: null
    });

    dispatch(
      resetParams(BREEDER_POST_ID, BREEDER_PROFILE_ID, SLIDE_IDX, {
        hash: true
      })
    );

    // snoopy + droopy set post_id in query (ie, not hash)
    dispatch(resetParams(BREEDER_POST_ID, { hash: false }));
  }

  function openPostModal(postId, breederId, slideIndex = 0) {
    dispatch(
      pushParams(
        {
          [BREEDER_POST_ID]: postId,
          [SLIDE_IDX]: slideIndex,
          [BREEDER_PROFILE_ID]: breederId
        },
        { hash: true }
      )
    );
  }

  return {
    breederPost: breederPostData?.value?.breeder_post || {},
    isBreederPostModalOpen,
    isLoading: breederPostData.isLoading,
    openPostModal,
    onModalClose: resetBreederPostParams,
    refetchBreederPost: breederPostData.refetch,
    selectedMediaIdx
  };
}
